

.easy-autocomplete {



	/* Theme Square */

	$square-grey: #7f8c8d;

	&.eac-square {	
		font-family: "Roboto", "Helvetica Neue",Helvetica,Arial,sans-serif;

		input {
			border: 1px solid $square-grey;
			border-radius: 0;
		    color: $square-grey;
		    font-family: inherit;
		    font-size: 18px;
		    font-style: italic;
		    font-weight: 300;
		    margin: 0;
		    min-width: 350px;
		    padding: 12px 43px 12px 15px;
		}


		ul {
            border-color: $square-grey;
            border-top: 0;
            box-shadow: none;
            top: 0;

            li, .eac-category {
                border-color: $square-grey;
                font-family: inherit;
                font-size: 16px;
                font-weight: 300;
                padding: 6px 12px;
                transition: all 0.4s ease 0s;

                * {
                	font-weight: 300;
                }

                &.selected {
                    background-color: #f1f1f1;
                    font-weight: 300;
                }

                b {
                	font-weight: 600;
                }

            }
        }

	}



	/* Round */
	$round-color: #888;
	$round-blue: #3079ed;

	&.eac-round {
		font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;

		input {
			border: 2px solid $round-color;
			border-radius: 10px;
		    color: $round-color;
		    font-family: inherit;
		    font-size: 16px;
		    font-weight: 400;
		    margin: 0;
		    min-width: 300px;
		    padding: 10px;

		    &:hover, &:focus {
		    	border-color: $round-blue;

		    }
		}

		ul {
			background: none;
            border-color: $round-color;
            border-width: 0;
            box-shadow: none;
            min-width: 300px;
            top: 20px;

            li, .eac-category {
            	background: #fff;
                border-color: $round-blue;
                border-width: 0 2px;
                font-size: 14px;
                padding: 8px 12px;
                transition: all 0.4s ease 0s;

                &.selected {
                    background-color: lighten($round-blue, 35%);
                }

                &:first-child {
                	border-radius: 10px 10px 0 0;
                	border-width: 2px 2px 0;
                }

                &:last-child {
                	border-radius: 0 0 10px 10px;
                	border-width: 0 2px 2px;
                }

                b {
                	font-weight: 700;
                }

            }
        }		

	}


	/* Funky */
	$funky-green: #07ED89;
	$funky-purple: #ED076B;
	$funky-orange: #8907ED;

	&.eac-funky {
		font-family: "Inconsolata", "Helvetica Neue",Helvetica,Arial,sans-serif;


		input {
			background: $funky-green;
			border: 0;
			border-radius: 0;
			color: $funky-purple;
			font-family: inherit;
			font-size: 18px;
			min-width: 240px;
			padding: 12px;
		}


		ul {
			background: transparent;
			border: 0;

			li, .eac-category {
				background: transparent;
				border: 0;
				color: $funky-purple;
				font-size: 18px;
				padding: 4px 12px;
				transition: all 0.4s ease 0s;

				&.selected {
					color: $funky-orange;
				}

			}

		}

	}

	/* Solid */

	&.eac-solid {

		input {
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) inset;
			border-color: #ddd;
		    color: #666;
		    font-size: 13px;
		    min-height: 0;
		    padding: 6px 12px;
		}


		ul {
			
			li, .eac-category {
				transition: all 0.4s ease 0s;

				&.selected {
					background-color: #f2f2f2;

			
				}

			}

		}

	}

	/* Plate */

	$yellow: rgba(255, 212, 100, 1);

	&.eac-plate {
		font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;


		input {
		    font-size: 13px;
		    padding: 6px 12px;
		}


		ul {
			background: rgba(181,181,181, 1);
			border-radius: 6px;
			border-width: 0;
			top: 10px;

			li, .eac-category {
				background: rgba(181,181,181, 1);
				color: #FAFAFA;
				font-size: 12px;

				transition: all 0.4s ease 0s;

				b {
					color: $yellow;
				}

				&.selected {
					background: rgba(101,101,101, 1);
				}

               &:first-child {
                	border-radius: 6px 6px 0 0;
                }

                &:last-child {
                	border-radius: 0 0 6px 6px;
                }

			}

		}

	}

	&.eac-plate-dark {
		font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;


		input {
		    font-size: 13px;
		    padding: 6px 12px;
		}


		ul {
			background: rgba(33,33,33, 1);
			border-radius: 6px;
			border-width: 0;
			top: 10px;

			li, .eac-category {
				background: rgba(33,33,33, 1);
				color: #FAFAFA;
				font-size: 12px;

				transition: all 0.4s ease 0s;

				b {
					color: $yellow;
				}

				&.selected {
					background: rgba(101,101,101, 1);
				}

               &:first-child {
                	border-radius: 6px 6px 0 0;
                }

                &:last-child {
                	border-radius: 0 0 6px 6px;
                }

			}

		}

	}
}

